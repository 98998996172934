import React, { useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row
} from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Box } from '../../modules/common/components/box';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import { HSRLightCone } from '../../modules/hsr/common/components/hsr-light-cone';
import Countdown from 'react-countdown';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const HSRIndexPage: React.FC = () => {
  const [currentRegion, setCurrentRegion] = useState('NA');
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page hsr '} game="hsr">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Honkai: Star Rail wiki and database</h1>
        <h2>
          Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse
          turn-based space fantasy RPG. Check out our guides, character reviews,
          tier list and more!
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      {/* <SectionHeader title="Promotion" />
      <div className="promo-top">
        <OutboundLink
          href="https://topup.bot/?game=hsr&utm_source=prydwen&utm_medium=referral&utm_campaign=january"
          target="_blank"
        >
          <StaticImage
            src="../../images/starrail/promo.webp"
            alt="Toptup.bot Promotion"
            className="desktop"
          />
          <StaticImage
            src="../../images/starrail/promo_mobile.webp"
            alt="Toptup.bot Promotion"
            className="mobile"
          />
          <p className="mobile text">
            <strong>Topup.bot</strong> - Oneiric Shards at a 20% discount,
            delivered instantly to UID!
          </p>
        </OutboundLink>
      </div> */}
      <SectionHeader title="Active codes" />
      <div className="codes">
        <Box isCentered>
          <p className="code">STARRAILGIFT</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 26.04.2023</p>
        </Box>
        <Box isCentered>
          <p className="code">BS3265PKCVXT</p>
          <p className="rewards">Stellar Jades + EXP materials</p>
          <p className="date">Released on 14.01.2025</p>
        </Box>
        <Box isCentered>
          <p className="code">RTKJPM6JVCFF</p>
          <p className="rewards">Stellar Jades + EXP materials</p>
          <p className="date">Released on 14.01.2025</p>
        </Box>
        <Box isCentered>
          <p className="code">EAJJPMN3DDE3</p>
          <p className="rewards">Stellar Jades + EXP materials</p>
          <p className="date">Released on 14.01.2025</p>
        </Box>
        <Box isCentered>
          <p className="code">ABC1CDEF0123</p>
          <p className="rewards">Stellar Jades + EXP materials</p>
          <p className="date">Released on 15.01.2025</p>
        </Box>
        <Box isCentered>
          <p className="code">HSRAWAITSYOU</p>
          <p className="rewards">Stellar Jades + EXP materials</p>
          <p className="date">Released on 15.01.2025</p>
        </Box>
        <Box isCentered>
          <p className="code">XYZ7OPQR9012</p>
          <p className="rewards">Stellar Jades + EXP materials</p>
          <p className="date">Released on 06.02.2025</p>
        </Box>
      </div>
      {/* <span className="new">NEW!</span>{' '} */}
      <SectionHeader title="Play on Mac" />
      <div className="banner-hsr-mac">
        <div className="cta">
          <h4>Play Honkai: Star Rail on Mac!</h4>
          <OutboundLink href="https://bstk.me/kHAAc94mA" target="_blank">
            <Button variant="primary">Play now on Mac</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            {/* <Accordion.Item className="planar-fissure" eventKey="44412">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Planar Fissure</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1735117200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1735095600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1735070400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/12/18 04:00:00 – 2024/12/25
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Planar Ornaments
                  obtained from the immersion device will be doubled when
                  challenging the Simulated Universe. The number of daily double
                  reward opportunities is limited and can be viewed on the
                  Planar Fissure event page.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            {/* <Accordion.Item className="realm" eventKey="444131">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Realm of the Strange</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1736326800000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1736305200000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1736280000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/01/01 04:00:00 – 2025/01/08
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, the amount of
                  Relics obtained from the Cavern of Corrosion will be doubled.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item className="memory-of-chaos" eventKey="103">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (2.7)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1739786400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1739764800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1739736000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/01/06 04:00:00 – 2025/02/17
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: Summons 2 Trotters at the
                  beginning of each wave or each Cycle. When defeated, the
                  Trotters deal a set amount of DMG to all Elite enemies and
                  above.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="nameless-honor" eventKey="992">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Nameless Honor</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740387600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740366000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740340800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 3.0 update - 2025/02/24
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Trailblazers can
                  increase the level of Nameless Honor to obtain various
                  rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="odyssey" eventKey="998">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Gift of Odyssey</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740387600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740366000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740340800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 3.0 update - 2025/02/24
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: Trailblazers can receive daily
                  rewards in the Travel Log each day after logging in to the
                  game. Rewards will be unlocked in sequential order according
                  to the accrued login days of the Trailblazer.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="aglaea" eventKey="3005">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Deadly Seamstress</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740513600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740492000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740466800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/02/05 12:00:00 - 2025/02/25
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="aglaea"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Time Woven Into Gold" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="tingyun"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="hanya"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="sushang"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Geniuses' Greetings" />
                  <HSRLightCone mode="profile" name="Subscribe for More!" />
                  <HSRLightCone mode="profile" name="Dance! Dance! Dance!" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="robin" eventKey="3007">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Again?</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740513600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740492000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740466800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/02/05 12:00:00 - 2025/02/25
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="robin"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Flowing Nightglow" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="tingyun"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="hanya"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="sushang"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Geniuses' Greetings" />
                  <HSRLightCone mode="profile" name="Subscribe for More!" />
                  <HSRLightCone mode="profile" name="Dance! Dance! Dance!" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="boothill" eventKey="3006">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Howdy Partner</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740513600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740492000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740466800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/02/05 12:00:00 - 2025/02/25
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="boothill"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone
                    mode="profile"
                    name="Sailing Towards A Second Life"
                  />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="tingyun"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="hanya"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="sushang"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Geniuses' Greetings" />
                  <HSRLightCone mode="profile" name="Subscribe for More!" />
                  <HSRLightCone mode="profile" name="Dance! Dance! Dance!" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="silver-wolf" eventKey="3008">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">What Am I Doing Here?</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740513600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740492000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740466800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/02/05 12:00:00 - 2025/02/25
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="silver-wolf"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Incessant Rain" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="tingyun"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="hanya"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="sushang"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Geniuses' Greetings" />
                  <HSRLightCone mode="profile" name="Subscribe for More!" />
                  <HSRLightCone mode="profile" name="Dance! Dance! Dance!" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="apo" eventKey="104">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Apocalyptic Shadow (3.0)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740996000000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740974400000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740945600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/01/20 04:00:00 – 2025/03/03
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When an enemy with "Steadfast
                  Safeguard" is Weakness Broken, dispels control states for all
                  allies, recovers their Skill Points, and activates their
                  Ultimates. Increases the Skill DMG taken by all enemies by 25%
                  and increases Ultimate DMG taken by 15%.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="pure-fiction" eventKey="105">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Pure Fiction (3.0)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1742205600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1742184000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1742155200000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/02/03 04:00:00 – 2025/03/17
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: After allies use Skill to
                  attack enemy targets, every enemy target hit additional
                  accumulates 5 Grit Value for allies.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header ">
            <h5>Upcoming</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="garden" eventKey="445">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Garden of Plenty</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1739527200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1739505600000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1739476800000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/01/14 04:00:00 – 2025/01/21
                  04:00:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Golden and Crimson
                  Calyx will give double rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="memory-of-chaos" eventKey="103">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (3.0)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1739786400000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1739764800000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1739736000000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/02/18 04:00:00 – 2025/03/31
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: At the start of each Cycle,
                  regenerates 20% Energy for all ally characters. The Energy
                  regenerated by this effect can exceed the target's Max Energy.
                  Enables all memosprites on the field to immediately take
                  action again after their next action.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/star-rail/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_introgame.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/star-rail/guides/reroll"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_rerollguide.jpg"
              alt="Reroll"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/star-rail/tier-list"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_tierlist.png"
              alt="Tier list"
            />
          }
        />
        <CategoryCard
          title="Free characters"
          link="/star-rail/guides/free-characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_freechars.png"
              alt="Free characters"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/star-rail/characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_characters.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Early progression tips"
          link="/star-rail/guides/early-progression"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_earlyprog.png"
              alt="Early progression tips"
            />
          }
        />
        <CategoryCard
          title="Team building"
          link="/star-rail/guides/team-building"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_team.png"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Light Cones"
          link="/star-rail/guides/light-cones"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_cones_guide.png"
              alt="Light Cones"
            />
          }
        />
        <CategoryCard
          title="Relics"
          link="/star-rail/guides/relics"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_relicinfo.png"
              alt="Relics"
            />
          }
        />
        <CategoryCard
          title="Memory of Chaos Analytics"
          link="/star-rail/memory-of-chaos"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_fh.png"
              alt="Memory of Chaos"
            />
          }
        />
        <CategoryCard
          title="Pure Fiction Analytics"
          link="/star-rail/pure-fiction"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_pf.jpg"
              alt="Pure Fiction"
            />
          }
        />
        <CategoryCard
          title="Apocalyptic Shadow Analytics"
          link="/star-rail/apocalyptic-shadow"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_apo.jpg"
              alt="Apocalyptic Shadow"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Honkai: Star Rail Wiki and Database | Prydwen Institute"
    description="Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse turn-based space fantasy RPG. Check out our guides, character reviews, tier list and more!"
  />
);
